@use '@sats-group/ui-lib/tokens/spacing';
@use '@sats-group/ui-lib/tokens/light';
@use '@sats-group/ui-lib/tokens/corner-radius';

.my-members {
  $breakpoint: 600px;

  display: flex;
  flex-direction: column;
  background-color: light.$surface-primary-default;
  padding-bottom: spacing.$l;

  &__header {
    background-color: light.$background-secondary-default;
    margin: spacing.$m 0;

    @media (min-width: $breakpoint) {
      margin-top: spacing.$l;
    }
  }

  &__header-text-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: spacing.$m;
  }

  &__chips {
    display: flex;
    gap: spacing.$xxs;
    overflow-x: auto;
    align-items: flex-start;
    white-space: nowrap;
    margin: 0 auto;
    margin-bottom: spacing.$xl;
    margin-top: spacing.$m;
    max-width: 1000px;
    padding-left: spacing.$m;
    padding-right: spacing.$m;
    padding-bottom: 2px; // NOTE: Needed because of some weird clipping behavior in Safari
  }

  &__content {
    overflow: auto;
    border-top: 1px solid light.$ge-divider-default;
  }

  &__result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: spacing.$s;
  }

  &__pagination {
    margin: spacing.$m;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: spacing.$s;
    margin-top: spacing.$xxl;
  }

  &__empty-state-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: spacing.$l;
    border-radius: 50%;
    background-color: light.$surface-secondary-default;
  }

  &__empty-state-text {
    color: light.$on-surface-primary-alternate;
  }

  &__broaden-search {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: spacing.$xs;
    border-radius: corner-radius.$s;
    padding: spacing.$m;
    background-color: light.$surface-secondary-default;
    margin-top: spacing.$m;
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
    margin-top: spacing.$xl;
    margin-bottom: spacing.$xxl;
  }

  &__additional-links {
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$m;
  }

  &__additional-link {
    display: flex;
    align-items: center;
    padding-left: spacing.$m;
    gap: spacing.$s;
    text-decoration: none;

    &:not(:last-child) {
      .my-members__additional-link-inner {
        border-bottom: 1px solid light.$ge-border-default;
      }
    }

    &:hover {
      background-color: light.$surface-primary-hover;
      border-radius: corner-radius.$m corner-radius.$m 0 0;
    }

    &:last-child:hover {
      background-color: light.$surface-primary-hover;
      border-radius: 0 0 corner-radius.$m corner-radius.$m;
    }
  }

  &__additional-link-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: spacing.$s;
    padding-right: spacing.$m;
  }
}
